import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import captureError from '@/utils/capture-error';
import { aceApiClient, gmapsApiClient } from '@/utils/axios';
import {
    addEntityTranslations,
    addInterfaceTranslations,
    addSingleTranslation,
} from '@/utils/build-translations';
import { useAppStore } from '@/stores/app';
import i18n from '@/i18n';
import { Loader } from '@googlemaps/js-api-loader';
import { useConfirmationGateStore } from '@/stores/confirmation-gate';
import { useTeaserStore } from '@/stores/teaser';
import { useConfigurationFormStore }    from "@/stores/configuration-form";

export const useLocalizationStore = defineStore('localization', {
    state: () => ({
        countries: [],
        selectedCountry: null,
        availableLanguages: [],
        selectedLanguage: null,

        legalContent: [],
        confirmationGateData: null,

        configurationStore: useConfigurationFormStore(),
    }),
    actions: {
        async loadCountries() {
            const route = apiRoute('countries');

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'localization:load-countries',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'localization:load-countries', true, 'countries.loadCountries');
                return;
            }

            if (result) {
                this.countries = result.data;
            }
        },

        async setSelectedCountry(country) {
            const upperCaseCountry = country.toUpperCase();
            const foundCountry = this.countries.find((c) => c.contraction === upperCaseCountry);

            if (foundCountry) {
                if (!foundCountry.hasCropProtection && !foundCountry.hasSeedProtection) {
                    this.$notify(i18n.global.t('errors.noCropOrSeedProtection'), 'error');

                    return false;
                }

                this.$loading.startLoading('localization:set-selected-country');

                this.selectedCountry = upperCaseCountry;
                this.availableLanguages = foundCountry.languages;

                let defaultLang = this.availableLanguages.find((l) => l.isDefault === 1);

                if (!defaultLang && this.availableLanguages.length) {
                    defaultLang = this.availableLanguages[0];
                }

                addSingleTranslation(
                    this.$i18n,
                    'agsfApplicationName',
                    foundCountry.agsfApplicationName ?? 'AgSolutions Finder',
                    this.availableLanguages.map((l) => l.locale),
                );

                this.setSelectedLanguage(defaultLang);

                // Load translations.
                const languages = this.availableLanguages.map((l) => l.locale);

                const interfaceTranslationsForCountry = await this.getInterfaceTranslations(
                    country,
                    languages,
                    false,
                );
                const entityTranslationsForCountry = await this.getEntityTranslations(
                    country,
                    languages,
                    false,
                );

                // Merge fallback & actual translations and set entity & interface translations.
                const appStore = useAppStore();

                addEntityTranslations(
                    this.$i18n,
                    entityTranslationsForCountry,
                    appStore.translationMappings,
                    languages,
                );

                addInterfaceTranslations(this.$i18n, interfaceTranslationsForCountry, languages);

                // Load static content.
                await this.getStaticContent(country);

                // Load service teasers.
                const teaserStore = useTeaserStore();
                await teaserStore.loadServiceTeasers();

                // Check for confirmation gate.
                const confirmationGateStore = useConfirmationGateStore();
                confirmationGateStore.checkConfirmationGateState();

                // Load google-maps library with default language.
                const loader = new Loader({
                    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
                    version: 'weekly',
                    libraries: ['places'],
                    language: defaultLang.language,
                });
                await loader.importLibrary('maps');

                this.$loading.endLoading('localization:set-selected-country');

                return true;
            } else {
                this.selectedCountry = null;
                this.setSelectedLanguage(null);
                return false;
            }
        },

        async getInterfaceTranslations(
            countryCode,
            languages = ['en_ALL'],
            applyNewTranslations = true,
        ) {
            const params = {};
            if (countryCode !== null) {
                params.countryCode = countryCode;
            }

            const route = apiRoute('interfaceTranslations', null, params);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'localization:load-interface-translations',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'localization:load-interface-translations');
                return null;
            }

            if (result) {
                if (applyNewTranslations) {
                    addInterfaceTranslations(this.$i18n, result.data, languages);
                }

                return result.data;
            }

            return null;
        },

        async getEntityTranslations(
            countryCode,
            languages = ['en_ALL'],
            applyNewTranslations = true,
        ) {
            const params = {};
            if (countryCode !== null) {
                params.countryCode = countryCode;
            }

            const route = apiRoute('entityTranslations', null, params);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'localization:load-entity-translations',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'localization:load-entity-translations');
                return null;
            }

            if (result) {
                if (applyNewTranslations) {
                    const appStore = useAppStore();

                    addEntityTranslations(
                        this.$i18n,
                        result.data,
                        appStore.translationMappings,
                        languages,
                    );
                }

                return result.data;
            }

            return null;
        },

        async getStaticContent(countryCode) {
            const route = apiRoute('staticContent', null, { countryCode });

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'localization:load-static-content',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(
                    err,
                    'localization:load-static-content',
                    true,
                    'localization.loadStaticContent',
                );
                return;
            }

            if (result) {
                this.legalContent = result.data?.legal;
                this.confirmationGateData = result.data?.legal?.find(
                    (c) => c.isLegalConfirmationGate,
                );

                // Get all received types of static content.
                const types = Object.keys(result.data);
                const translationData = {};

                types.forEach((type) => {
                    const segments = result.data[type];

                    // Sort the segments/paragraphs.
                    segments.sort((a, b) => a.position - b.position);

                    // Build translations array and concat the segments.
                    const mapped = segments.reduce((acc, val) => {
                        if (val.content) {
                            // Add to translations
                            Object.keys(val.content).forEach((l) => {
                                if (!acc[l] || !acc[l]['content']) {
                                    acc[l] = {};
                                    acc[l]['content'] = '';
                                }

                                acc[l]['title'] = val.title[l];

                                const linkText = acc[l]['title'];
                                const content =
                                    val.type === 'link'
                                        ? `<a href="${val.content[l]}" target="_blank">${linkText}</a>`
                                        : val.content[l];

                                acc[l]['content'] += `${content}\n`;
                            });
                        }

                        return acc;
                    }, {});

                    translationData[type] = mapped;

                    // Add to translations
                    addSingleTranslation(
                        this.$i18n,
                        `static-content-${type}`,
                        mapped,
                        this.availableLanguages.map((l) => l.locale),
                    );
                });
            }
        },

        setSelectedLanguage(language) {
            this.selectedLanguage = language;

            if (language) {
                this.$i18n.locale = language.locale;
            }

            document.title = `BASF ${this.$i18n.t('agsfApplicationName') ?? 'AgSolutions Finder'}`;
        },

        async getGoogleMapsLocationResult(term, addressComponent = 'administrative_area_level_1') {
            const key = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
            const encodedInput = encodeURIComponent(term);
            const url = `/json?address=${encodedInput}&key=${key}&language=en`;

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'localization:get-gmaps-location-result',
                async () => to(gmapsApiClient.get(url)),
            );

            if (err) {
                captureError(err, 'localization:get-gmaps-location-result');
                return;
            }

            if (result && result.data?.results?.length) {
                const place = result.data.results[0];
                return place.address_components.find((c) => c.types.includes(addressComponent))
                    ?.long_name;
            }
        },
    },
    getters: {
        selectedCountryMeta: (state) =>
            state.countries.find((c) => c.contraction === state.selectedCountry),
        getParameterValueTranslationKey: (state) => (paramIdentifier, item) => {
            const isFunctionalGroup = paramIdentifier === 'hasAdviceFunctionalGroupCodes';
            let key;

            if (isFunctionalGroup) {
                key = state.$i18n.te(`functionalGroups.${item}.custom_name`)
                    ? `functionalGroups.${item}.custom_name`
                    : `functionalGroups.${item}.name`;
            } else {
                const isVariety = paramIdentifier === 'hasAdviceValidForVarietyName';
                if (isVariety) {
                    key = `parameterValues.${paramIdentifier}.${item}.${state.configurationStore.selectedCrop?.id}`;
                } else {
                    key = `parameterValues.${paramIdentifier}.${item}`;
                }
            }

            if (state.$i18n.te(key) || state.$i18n.te(key, 'en_ALL')) {
                return key;
            } else if (
                state.$i18n.te(`parameterValues.unsorted.${item}`) ||
                state.$i18n.te(`parameterValues.unsorted.${item}`, 'en_ALL')
            ) {
                return `parameterValues.unsorted.${item}`;
            }

            return item;
        },
        getParameterValueTranslation: (state) => (paramIdentifier, item) => {
            return state.$i18n.t(state.getParameterValueTranslationKey(paramIdentifier, item));
        },
    },
});
