import { defineStore } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import FormStepKeys from '@/utils/constants/form-step-keys';
import { useDeeplinkStore } from '@/stores/deeplink';
import { checkNextParameters, flattenTree, mergeTree } from '@/utils/tree-helpers';
import ParameterInputTypes from '@/utils/constants/parameter-input-types';
import { useLinksAndResourcesStore } from '@/stores/links-and-resources';
import { useLocalizationStore } from '@/stores/localization';
import { useAppStore } from '@/stores/app';
import { isInIframe } from '@/utils/helpers';

export const useConfigurationFormStore = defineStore('configuration-form', {
    state: () => ({
        formSteps: [],
        currentFormStep: FormStepKeys.CROP,

        selectedCrop: null,
        selectedGrowthStage: null,
        selectedTargets: [],
        userDisabledTargets: [],

        enteredTreeValues: {},
        additionalFilterInputs: {},
        isParameterSelectionValid: false,

        enteredCharacteristics: {},

        deeplinkStore: useDeeplinkStore(),
        cropProtectionStore: useCropProtectionStore(),
    }),
    actions: {
        // Form
        setFormSteps(formSteps) {
            this.formSteps = formSteps;
        },
        setCurrentStep(step) {
            this.currentFormStep = step;

            // Scroll to top of page.
            if (isInIframe()) {
                const parentEl = window.document.querySelector('#app');

                parentEl.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        },
        onForwardsClick() {
            if (this.canGoForwards) {
                const appStore = useAppStore();

                // Exception: Flow parameters is empty array -> skip parameters.
                const shouldSkipParameterPage =
                    this.currentFormStep === FormStepKeys.CROP &&
                    this.isParameterConfigurationPageHidden;

                if (shouldSkipParameterPage) {
                    this.markEntityForReload('growthStages');
                }

                // Exception: Only PGR is selected (CP), so we skip the targets page.
                const shouldSkipTargetsPage =
                    appStore.currentService === 'crop-protection' &&
                    this.currentFormStep === FormStepKeys.GROWTH_STAGE &&
                    this.isOnlyPGRFunctionalGroup;

                if (shouldSkipParameterPage || shouldSkipTargetsPage) {
                    this.setCurrentStep(this.currentFormStep + 2);
                    return;
                }

                this.setCurrentStep(this.currentFormStep + 1);
            }
        },
        onBackwardsClick() {
            if (this.canGoBack) {
                const appStore = useAppStore();

                // Same exceptions as above, just backwards.
                const shouldSkipParameterPage =
                    this.currentFormStep === FormStepKeys.GROWTH_STAGE &&
                    this.isParameterConfigurationPageHidden;

                if (shouldSkipParameterPage) {
                    this.markEntityForReload('growthStages');
                }

                const shouldSkipTargetsPage =
                    appStore.currentService === 'crop-protection' &&
                    this.currentFormStep === FormStepKeys.RESULT &&
                    this.isOnlyPGRFunctionalGroup;

                if (shouldSkipParameterPage || shouldSkipTargetsPage) {
                    this.setCurrentStep(this.currentFormStep - 2);
                    return;
                }

                this.setCurrentStep(this.currentFormStep - 1);
            }
        },

        // Crop
        async setSelectedCrop(crop) {
            this.selectedCrop = crop;
            this.markEntityForReload('parameterConfiguration');
            this.deeplinkStore.setHasSelectedAnotherCrop(true);

            const linksStore = useLinksAndResourcesStore();
            const appStore = useAppStore();

            let service = 'crop_protection';
            switch (appStore.currentService) {
                case 'seeds':
                    service = 'seed';
                    break;
                default:
                    service = 'crop_protection';
                    break;
            }

            await linksStore.loadCropSpecificLinks(crop.eppoCode, service);
        },
        // Growth-stage
        async setSelectedGrowthStage(growthState) {
            this.selectedGrowthStage = growthState;
            this.markEntityForReload('targets');
            this.markEntityForReload('recommendations');
        },
        // Targets
        setSelectedTargets(targets) {
            this.selectedTargets = targets;
            this.markEntityForReload('recommendations');
        },
        setUserDisabledTargets(disabledTargetsIDs) {
            this.userDisabledTargets = disabledTargetsIDs;
            this.markEntityForReload('recommendations');
        },
        removeDisabledTarget(targetID) {
            const index = this.userDisabledTargets.findIndex((t) => t === targetID);
            this.userDisabledTargets.splice(index, 1);
        },
        cleanUpSelectedTargets() {
            // Remove selected targets that don't exist anymore,
            // or if they are not wanted by the param config anymore.
            const selectionsToClear = [];

            this.selectedTargets.forEach((t) => {
                if (
                    !this.cropProtectionStore.targets.map((t) => t.id).includes(t.id) ||
                    !this.enteredTreeValues['hasAdviceFunctionalGroupCodes']?.value?.includes(
                        t.functionalGroupCode,
                    ) ||
                    !this.cropProtectionStore.parameterConfiguration?.functionalGroupInformation?.find(
                        (group) => group.value === t.functionalGroupCode,
                    )?.hasTargets
                ) {
                    selectionsToClear.push(t);
                }
            });
            selectionsToClear.forEach((t) =>
                this.selectedTargets.splice(this.selectedTargets.indexOf(t), 1),
            );
        },

        markEntityForReload(entity) {
            this.cropProtectionStore.setShouldLoadStatusForEntity(entity, true);
        },

        // Parameters
        createParameterTreeFields() {
            const possibleParameters = this.collectActiveFieldsInParameterConfiguration(false);

            possibleParameters.forEach((p) => {
                const savedParam = this.enteredTreeValues[p.apiExternalIdentifier];

                if (!savedParam) {
                    this.setValueByParameterIdentifier(p.apiExternalIdentifier, {
                        value: p.defaultValues,
                        parameter: p,
                    });
                }
            });

            this.removeHiddenFieldFromUserInput();
        },
        setValueByParameterIdentifier(identifier, value) {
            if (!value.value) return;
            if (!this.enteredTreeValues.hasOwnProperty(identifier)) {
                this.enteredTreeValues[identifier] = null;
            }

            // Filter out values that are not a part of the parameter values.
            value.value = value.value.filter(
                (v) =>
                    v !== null &&
                    (value.parameter.orderedValues.includes(v?.toString()) ||
                        value.value[0] === false),
            );

            this.enteredTreeValues[identifier] = value;

            this.markEntityForReload('growthStages');
        },
        resetStore() {
            this.$reset();
        },
        removeHiddenFieldFromUserInput(includeInactive = true) {
            const visibleInputs = this.collectActiveFieldsInParameterConfiguration(includeInactive);
            const flattenedTree = flattenTree(
                this.cropProtectionStore.parameterConfiguration?.recommendationFlowParameters,
                this.enteredTreeValues,
            );

            Object.keys(this.enteredTreeValues).forEach((key, _) => {
                // Clear unavailable values.
                const param = this.enteredTreeValues[key];
                const enteredValues = param?.value;
                const allowedValues =
                    flattenedTree[param?.parameter?.id]?.flatMap((p) =>
                        p.orderedValues.map(String),
                    ) ?? [];
                this.enteredTreeValues[key].value = this.enteredTreeValues[key].value.map(toString);
                this.enteredTreeValues[key].value = enteredValues.filter((value) =>
                    allowedValues.includes(value.toString()),
                );

                // Clear unused parameters.
                if (
                    !visibleInputs.find((p) => p.apiExternalIdentifier === key) ||
                    (this.enteredTreeValues[key]?.value?.length === 0 &&
                        !this.enteredTreeValues[key].parameter.orderedValues.includes('_blank_'))
                ) {
                    // delete this.enteredTreeValues[key];
                }
            });
        },
        collectActiveFieldsInParameterConfiguration(includeInactive = false) {
            const parameterConfig =
                this.cropProtectionStore.parameterConfiguration?.recommendationFlowParameters;

            if (!parameterConfig) return [];

            const parameters = includeInactive
                ? this.collectAllFields(parameterConfig)
                : this.collectFields(parameterConfig);

            return parameters;
        },
        // Collect all existing parameters in all branches of the give parameter.
        collectAllFields(parameter, fields = []) {
            const branches = Object.keys(parameter.values);

            if (!fields.find((f) => f.id === parameter.id)) {
                fields.push(parameter);
            }

            branches.forEach((branch) => {
                const nextParameter = parameter.values[branch];

                if (nextParameter) {
                    return this.collectAllFields(nextParameter, fields);
                }
            });

            return fields;
        },
        collectFields(parameter, fields = []) {
            return mergeTree(
                parameter,
                this.enteredTreeValues,
                this.cropProtectionStore.parameterConfiguration.recommendationFlowParameterOrder,
            );
        },
        clearTreeValues() {
            this.enteredTreeValues = {};
        },
        validateParameterConfiguration() {
            const config = this.collectFields(
                this.cropProtectionStore.parameterConfiguration?.recommendationFlowParameters,
            );
            const requiredParameters = config.filter(
                (p) => this.isParameterRequired(p) && !p.orderedValues.includes('_blank_'),
            );
            const requiredParameterKeys = requiredParameters.map((p) => p.apiExternalIdentifier);
            const correspondingValues = this.enteredTreeValues;

            if (requiredParameterKeys.length === 0) {
                this.isParameterSelectionValid = true;
                return false;
            }

            for (const key of requiredParameterKeys) {
                const param = requiredParameters.find((p) => p.apiExternalIdentifier === key);
                const valueWrapper = correspondingValues[key];
                const value = valueWrapper?.value;
                const isValidSelection = checkNextParameters(param, valueWrapper)?.length > 0;

                if (!isValidSelection || !valueWrapper || !value || value?.length === 0) {
                    this.isParameterSelectionValid = false;
                    return false;
                }
            }

            this.isParameterSelectionValid = true;
            return true;
        },
        setAdditionalInputValueByParameterIdentifier(identifier, value) {
            if (!value.value) return;
            if (!this.additionalFilterInputs.hasOwnProperty(identifier)) {
                this.additionalFilterInputs[identifier] = null;
            }

            this.additionalFilterInputs[identifier] = value;
        },
        resetAllUserInputs(updateRoute = true) {
            this.setSelectedTargets([]);
            this.setSelectedGrowthStage(null);
            this.clearTreeValues();
            this.additionalFilterInputs = {};
            this.enteredCharacteristics = {};

            if (updateRoute) {
                const query = { ...this.router.currentRoute.query };
                delete query.userLocation;
                this.router.push({ query });
            }
        },
        clearAdditionalFilterInputs() {
            this.additionalFilterInputs = {};
        },
        setCharacteristicByIdentifier(identifier, value) {
            if (!this.enteredCharacteristics?.hasOwnProperty(identifier)) {
                this.enteredCharacteristics[identifier] = value;
            }

            this.enteredCharacteristics[identifier] = value;
        },
        setCharacteristicsFromList(characteristics) {
            this.clearCharacteristics();

            if (characteristics?.length) {
                characteristics.forEach((c) => {
                    const val = c.defaultValues?.length ? c.defaultValues[0] == 'true' : false;
                    this.enteredCharacteristics[c.externalIdentifier] = val;
                });
            }
        },
        clearCharacteristics() {
            this.enteredCharacteristics = {};
        },
    },
    getters: {
        canGoForwards: (state) => {
            if (state.$loading.isLoading('deeplink:apply-deep-link')) {
                return false;
            }

            if (state.currentFormStep === FormStepKeys.CROP) {
                return state.selectedCrop;
            } else if (state.currentFormStep === FormStepKeys.PARAMETERS) {
                return (
                    state.isParameterSelectionValid &&
                    !state.$loading.isLoading('crop-protection:load-parameter-configuration')
                );
            } else if (state.currentFormStep === FormStepKeys.GROWTH_STAGE) {
                return (
                    state.selectedGrowthStage &&
                    !state.$loading.isLoading('crop-protection:load-growth-stages')
                );
            } else if (state.currentFormStep === FormStepKeys.TARGETS) {
                return (
                    !state.$loading.isLoading('crop-protection:load-targets') &&
                    state.isTargetSelectionValid
                );
            }
            return false;
        },
        canGoBack: (state) => state.currentFormStepIndex > 0,
        isParameterRequired: (state) => (parameter) => {
            return parameter.required && parameter.type !== ParameterInputTypes.BOOLEAN;
        },
        isBottomBarActive: (state) => {
            const appStore = useAppStore();
            return (
                state.currentFormStepData &&
                state.router?.currentRoute?.name !== 'no-service' &&
                state.router?.currentRoute?.name !== 'maintenance' &&
                (appStore.currentService === 'seeds'
                    ? state.currentFormStepData?.identifier !== FormStepKeys.SEED_RESULT
                    : state.currentFormStepData?.identifier !== FormStepKeys.RESULT)
            );
        },
        currentFormStepIndex: (state) => {
            return state.formSteps.findIndex((s) => s.identifier === state.currentFormStep);
        },
        currentFormStepData: (state) => {
            return (
                state.formSteps.find((s) => s.identifier === state.currentFormStep) ??
                state.formSteps[state.currentFormStep]
            );
        },

        getValueByParameterName: (state) => (key) => {
            return state.enteredTreeValues[key]?.value;
        },
        getAdditionalInputValueByParameterName: (state) => (key) => {
            return state.additionalFilterInputs[key]?.value;
        },
        getCharacteristicByIdentifier: (state) => (identifier) =>
            state.enteredCharacteristics[identifier],

        activeTargets: (state) =>
            state.selectedTargets.filter((t) => !state.userDisabledTargets.includes(t.id)),
        isTargetDisabled: (state) => (targetID) =>
            state.userDisabledTargets.findIndex((t) => t === targetID) !== -1,
        areTargetsOfFunctionalGroupSelected: (state) => (group) =>
            state.activeTargets.find((t) => t.functionalGroupCode === group) !== undefined,
        isTargetSelectionValid: (state) => {
            const selectedFunctionalGroups =
                state.enteredTreeValues['hasAdviceFunctionalGroupCodes'];

            // If no fct. group is given, the user has to select a target.
            if (!selectedFunctionalGroups || selectedFunctionalGroups?.value?.length === 0) {
                return state.activeTargets.length > 0;
            }

            const isPGR = selectedFunctionalGroups?.value?.includes('PGR') ?? false;
            const isOnlyPGR = isPGR && selectedFunctionalGroups?.value?.length === 1;

            // If only PGR is selected, we skip the targets page entirely.
            // (Skipping logic handled in onForwardsClick())
            if (isOnlyPGR) {
                return true;
            }

            // Extract info which functional groups actually require targets.
            const functionalGroupsThatRequireTargets =
                state.cropProtectionStore.parameterConfiguration?.functionalGroupInformation
                    .filter((d) => d.hasTargets)
                    .map((d) => d.value);

            // Iterate through all selected fct. groups and for each group,
            // check if target(s) are selected.
            const functionalGroupHasTarget = {};
            selectedFunctionalGroups?.value
                ?.filter((g) => g !== 'PGR')
                .forEach(
                    (g) =>
                        (functionalGroupHasTarget[g] =
                            state.areTargetsOfFunctionalGroupSelected(g)),
                );

            // We have a group selected that's not a PGR.
            if (selectedFunctionalGroups.value?.length === 1 && !isPGR) {
                const selectedGroup = selectedFunctionalGroups.value[0];

                // The selected group requires a selected target.
                if (functionalGroupsThatRequireTargets.includes(selectedGroup)) {
                    return functionalGroupHasTarget[selectedGroup];
                }

                return true;
            } else if (selectedFunctionalGroups.value?.length > 1) {
                const isAtLeastOneTargetValid = false;

                for (let i = 0; i < selectedFunctionalGroups.value.length; i++) {
                    const group = selectedFunctionalGroups.value[i];

                    if (functionalGroupHasTarget[group]) {
                        return true;
                    }
                }

                return isAtLeastOneTargetValid;
            }

            return false;
        },
        isOnlyPGRFunctionalGroup: (state) =>
            state.enteredTreeValues['hasAdviceFunctionalGroupCodes']?.value?.length === 1 &&
            state.enteredTreeValues['hasAdviceFunctionalGroupCodes']?.value[0] === 'PGR',
        baseRequestParameters:
            (state) =>
            (usedInRecommendationRequest = false) => {
                const countryStore = useLocalizationStore();
                const enteredValues = state.enteredTreeValues;
                const countryCode = countryStore.selectedCountry;

                // Take all entered parameters and create parameter object for request.
                // => { hasAdviceRegion: ['Hamburg'], ... }
                const formParams = state.generateFormParams(
                    enteredValues,
                    usedInRecommendationRequest,
                );

                return {
                    countryCode,
                    ...formParams,
                };
            },
        generateFormParams:
            (state) =>
            (enteredValues, usedInRecommendationRequest = false) => {
                const keyForRequest = usedInRecommendationRequest
                    ? 'externalIdentifier'
                    : 'apiExternalIdentifier';

                return Object.keys(enteredValues).reduce((acc, key) => {
                    const param = enteredValues[key];
                    const identifier = param?.parameter[keyForRequest];
                    const isMultiSelect =
                        param?.parameter?.type === ParameterInputTypes.MULTI_SELECT;

                    const val = isMultiSelect ? param?.value : param?.value[0];

                    // Make sure we don't send empty values.
                    if (
                        val !== null &&
                        val !== undefined &&
                        (val.length || typeof val === 'boolean' || typeof val === 'number')
                    ) {
                        acc[identifier] = val;
                    }

                    return acc;
                }, {});
            },
        isParameterConfigurationPageHidden: (state) =>
            Array.isArray(
                state.cropProtectionStore.parameterConfiguration?.recommendationFlowParameters,
            ) &&
            state.cropProtectionStore.parameterConfiguration?.recommendationFlowParameters
                .length === 0,
    },
});
