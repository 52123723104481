<template>
    <div class="sidebar-compare-tankmix">
        <div class="sidebar-compare-tankmix-content">
            <div class="sidebar-compare-tankmix__header">
                <!--Headlines-->
                <table class="data-table">
                    <tbody>
                        <tr>
                            <td>
                                <Toggle
                                    v-model="showOnlyDifferences"
                                    :label="
                                        $t('recommendations.characteristicCompareDifferenceFilter')
                                    "
                                />
                            </td>
                            <td v-for="hl in tableColumnHeadlines">
                                {{ hl }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="sidebar-compare-tankmix_tables">
                <DataTable
                    v-for="(cTable, i) in characteristicsGroups"
                    :data="getTransformedTableDataForGroup(cTable)"
                    :headline="getTableHeadline(cTable)"
                    :showOnlyDifferences="showOnlyDifferences"
                    :sourceRow="i === characteristicsGroups.length - 1 ? getTableSources() : null"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useSeedStore } from '@/stores/seed';
import { useCropProtectionStore } from '@/stores/crop-protection';
import Toggle from '@/components/atoms/Toggle.vue';
import DataTable from '@/components/atoms/DataTable.vue';
import { useLocalizationStore } from '@/stores/localization';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import translationArrayHelper from '@/utils/translation-array-helper';

export default {
    name: 'SidebarCompareTankmix',
    components: { DataTable, Toggle },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showOnlyDifferences: false,
        };
    },
    computed: {
        ...mapState(useLocalizationStore, ['selectedLanguage']),
        ...mapState(useSeedStore, [
            'tankmixesForComparison',
            'seedRecommendations',
            'getDataValueFromDataListItem',
            'getCardParameterValueTranslationKey',
        ]),
        ...mapState(useCropProtectionStore, ['getProductById']),
        ...mapState(useConfigurationFormStore, ['enteredTreeValues']),

        tankmixes() {
            return this.tankmixesForComparison.map((id) =>
                this.seedRecommendations.advices.find((a) => a.id === id),
            );
        },
        productsToDisplay() {
            return this.productIdsToDisplay.map((pId) => {
                return this.getProductById(pId);
            });
        },
        productIdsToDisplay() {
            return Array.from(
                new Set(
                    this.tankmixes.flatMap((mix) => mix.adviceProducts.flatMap((p) => p.productId)),
                ),
            );
        },
        tableColumnHeadlines() {
            return this.productIdsToDisplay.map((id) => this.$t(`products.${id}.name`));
        },
        characteristicsGroups() {
            const data = [...this.seedRecommendations.secondaryDataList];

            data.sort((a, b) => a.order - b.order);

            return data;
        },
    },
    methods: {
        getTableHeadline(table) {
            return this.$t(`seedAdviceSettingsGroup.${table.externalIdentifier}`);
        },
        // Get transformed product characteristics for a secondary data group.
        getTransformedTableDataForGroup(group) {
            const data = group.children;

            data.sort((a, b) => a.order - b.order);

            return data
                .map((attr) => {
                    return {
                        attribute: attr.translatedNames,
                        values: this.productsToDisplay.map((p) => this.getCellData(attr, p)),
                        sources: this.productsToDisplay.map((p) =>
                            this.getCellData(attr, p, 'source'),
                        ),
                    };
                })
                .filter(
                    (row) =>
                        row.values?.filter((v) => v !== null && v !== undefined && v !== '')
                            ?.length,
                );
        },
        getCellData(attr, product, field = 'value') {
            let val = !!this.getDataValueFromDataListItem(attr, product)
                ? this.getDataValueFromDataListItem(attr, product)[field]
                : null;

            if (val && field === 'value') {
                val = this.getCardParameterValueTranslationKey(val);
            }

            return val;
        },
        getTableSources() {
            return {
                attribute: this.$t('recommendations.characteristicCompareSource'),
                values: this.productsToDisplay.map((p, i) => {
                    const source = p.translatedSources;

                    return source?.length
                        ? translationArrayHelper(source, this.$i18n.locale, 'locale')
                        : null;
                }),
            };
        },
    },
};
</script>
