<template>
    <div class="seed-calculator-result">
        <template
            v-if="
                resultData?.result !== null && resultData.result !== 0 && resultData.result !== -1
            "
        >
            <strong
                >{{ resultData.result }}
                {{
                    $te(`units.${resultUnits.outputUnit}.custom_short`)
                        ? $t(`units.${resultUnits.outputUnit}.custom_short`)
                        : $t(`units.${resultUnits.outputUnit}.short`)
                }}</strong
            >
            <span>{{ $t('recommendations.seedCalculatorSowingRateResultLabel') }}</span>
            <div
                v-if="
                    ($te('recommendations.seedCalculatorSowingRateResultHint') ||
                        $te('recommendations.seedCalculatorSowingRateResultHint', 'en_ALL')) &&
                    $t('recommendations.seedCalculatorSowingRateResultHint')
                "
                class="seed-calculator-result__hint"
            >
                {{ $t('recommendations.seedCalculatorSowingRateResultHint') }}
            </div>
        </template>

        <template
            v-if="
                resultData?.requiredAmount !== null &&
                resultData?.requiredAmount !== 0 &&
                resultData.requiredAmount !== -1
            "
        >
            <strong
                >{{ resultData.requiredAmount }}
                {{
                    $te(`units.${resultUnits.requiredAmountUnit}.custom_short`)
                        ? $t(`units.${resultUnits.requiredAmountUnit}.custom_short`)
                        : $t(`units.${resultUnits.requiredAmountUnit}.short`)
                }}
            </strong>
            <span>{{ $t('recommendations.seedCalculatorSeedsRequiredResultLabel') }}</span>
            <div
                v-if="
                    ($te('recommendations.seedCalculatorSeedsRequiredResultHint') ||
                        $te('recommendations.seedCalculatorSeedsRequiredResultHint', 'en_ALL')) &&
                    $t('recommendations.seedCalculatorSeedsRequiredResultHint')
                "
                class="seed-calculator-result__hint"
            >
                {{ $t('recommendations.seedCalculatorSeedsRequiredResultHint') }}
            </div>
        </template>

        <template
            v-if="
                resultData?.seedsPerMeter !== null &&
                resultData?.seedsPerMeter !== 0 &&
                resultData.seedsPerMeter !== -1
            "
        >
            <strong>{{ resultData.seedsPerMeter }} </strong>
            <span>{{ $t('recommendations.seedCalculatorSeedsPerMeterResultLabel') }}</span>
            <div
                v-if="
                    ($te('recommendations.seedCalculatorSeedsPerMeterResultHint') ||
                        $te('recommendations.seedCalculatorSeedsPerMeterResultHint', 'en_ALL')) &&
                    $t('recommendations.seedCalculatorSeedsPerMeterResultHint')
                "
                class="seed-calculator-result__hint"
            >
                {{ $t('recommendations.seedCalculatorSeedsPerMeterResultHint') }}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SeedCalculatorResult',
    props: {
        resultData: {
            type: Object,
            required: true,
        },
        resultUnits: {
            type: Object,
            required: true,
        },
    },
};
</script>
