<template>
    <div v-if="shouldShowTeasers" class="service-teaser-list">
        <h2 class="service-teaser-list__headline">{{ title || $t('services.headline') }}</h2>
        <div class="service-teaser-list__background" />

        <GridList class="service-teaser-list__ul" :columns="3">
            <li v-for="service in availableServicesOrdered">
                <ServiceTeaser
                    :title="getTeaserTranslation(service.title)"
                    :category="getServiceTypeLabel(service)"
                    :linkText="getTeaserTranslation(service.button)"
                    :link="getServiceLink(service)"
                    :isExternal="service.target !== 'crop' && service.target !== 'seed'"
                    :text="getTeaserTranslation(service.text)"
                    :icon="getTeaserIcon(service)"
                />
            </li>
        </GridList>
    </div>
</template>

<script>
import ServiceTeaser from '@/components/atoms/ServiceTeaser.vue';
import GridList from '@/components/atoms/GridList.vue';
import { mapActions, mapState } from 'pinia';
import { useTeaserStore } from '@/stores/teaser';

export default {
    name: 'ServiceTeaserList',
    components: { GridList, ServiceTeaser },
    props: {
        title: {
            type: String,
        },
    },
    computed: {
        ...mapState(useTeaserStore, [
            'availableServicesOrdered',
            'getServiceLink',
            'getServiceTypeLabel',
            'getTeaserTranslation',
            'getTeaserIcon',
        ]),

        shouldShowTeasers() {
            const routeTargetMapping = {
                'crop-protection': 'crop',
                seeds: 'seed',
            };
            const route = this.$route.name?.split('.')[0];

            return (
                this.availableServicesOrdered?.filter((s) => {
                    return s.target !== routeTargetMapping[route];
                }).length > 0
            );
        },
    },
    methods: {
        ...mapActions(useTeaserStore, ['loadServiceTeasers']),
    },
};
</script>
