<template>
    <div v-if="filteredData?.length" class="data-table-wrapper">
        <span v-if="headline" class="data-table-wrapper__headline">{{ headline }}</span>
        <table class="data-table">
            <tbody>
                <tr v-for="(row, rowIndex) in filteredData" :key="rowIndex">
                    <td>
                        <span>{{
                            Array.isArray(row.attribute)
                                ? translationArrayHelper(
                                      row.attribute,
                                      this.$i18n.locale.slice(0, 2),
                                  )
                                : row.attribute
                        }}</span>
                    </td>
                    <td v-for="(value, colIndex) in row.values" :key="colIndex">
                        <span>{{ value }}</span>
                    </td>
                </tr>
                <tr
                    v-if="sourceRow && sourceRow.values?.some((v) => v)"
                    class="data-table--source-row"
                >
                    <td>
                        <span>{{ sourceRow.attribute }}</span>
                    </td>
                    <td v-for="(value, colIndex) in sourceRow.values" :key="colIndex">
                        <span>{{ value }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import translationArrayHelper from '../../utils/translation-array-helper';

export default {
    name: 'DataTable',
    methods: { translationArrayHelper },
    props: {
        headline: {
            type: String,
        },
        data: {
            type: Array,
            required: true,
        },
        showOnlyDifferences: {
            type: Boolean,
            default: false,
        },
        sourceRow: {
            type: Object,
        },
    },
    computed: {
        filteredData() {
            if (this.showOnlyDifferences) {
                return this.differences;
            }
            return this.data;
        },
        differences() {
            return this.data.filter((row) => {
                const uniqueValues = new Set(row.values);
                return uniqueValues.size > 1;
            });
        },
    },
};
</script>
