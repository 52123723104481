<template>
    <div class="dropdown-menu">
        <label v-if="label" :for="identifier" class="dropdown-menu__label">
            {{ label }}
        </label>
        <DropdownMenu
            :isOpen="isOpened"
            :overlay="false"
            :closeOnClickOutside="false"
            :dropup="shouldOpenUpwards"
            :withDropdownCloser="!multiple"
            :id="identifier"
            :class="classes"
            ref="dropdown"
            @opened="onOpened"
            @closed="onClosed"
        >
            <template #trigger>
                <template v-if="!isOpened">
                    <template v-if="selectedItems.length">
                        <span class="v-dropdown-menu__selected">
                            <slot name="head" v-bind:items="selectedItemsSorted"></slot>
                            <span v-if="suffix" class="v-dropdown-menu__selected-suffix">
                                {{ suffix }}
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <div v-if="icon" class="fas" :class="icon" />
                        <span>{{ placeholder }}</span>
                    </template>
                </template>
                <template v-else>
                    <input
                        v-model="searchTerm"
                        ref="searchInput"
                        type="text"
                        @click.prevent.stop="() => 0"
                        @keydown.down.prevent="focusNextItem"
                        @keydown.up.prevent="focusPrevItem"
                        @keydown.esc.prevent="escapeClick"
                    />
                </template>
                <div
                    v-if="clearable"
                    class="v-dropdown-menu__icon-btn"
                    :title="$t('form.clearAll')"
                    dropdown-closer
                    @click.stop="onClearClick"
                />
                <div class="v-dropdown-menu__icon-caret" />
            </template>

            <template #body>
                <ul ref="options">
                    <li
                        v-for="(item, i) in selectedFilteredItems"
                        :key="i"
                        dropdown-closer
                        class="v-dropdown-menu__item"
                        :class="{ active: isItemActive(item) }"
                        tabindex="0"
                        @click="onItemClick(item)"
                        @keydown.down.prevent="focusNextItem"
                        @keydown.up.prevent="focusPrevItem"
                        @keydown.enter.prevent="confirmSelection"
                        @keydown.space.prevent="confirmSelection"
                        @keydown.esc.prevent="escapeClick"
                    >
                        <Toggle v-if="multiple" :modelValue="isItemActive(item)" />
                        <slot name="item" v-bind:item="item"></slot>
                        <span v-if="suffix" class="v-dropdown-menu__item-suffix">{{ suffix }}</span>
                    </li>
                    <li
                        v-for="(item, i) in filteredItemsWithoutSelected"
                        :key="i"
                        dropdown-closer
                        class="v-dropdown-menu__item"
                        :class="{ active: isItemActive(item) }"
                        tabindex="0"
                        @click="onItemClick(item)"
                        @keydown.down.prevent="focusNextItem"
                        @keydown.up.prevent="focusPrevItem"
                        @keydown.enter.prevent="confirmSelection"
                        @keydown.space.prevent="confirmSelection"
                        @keydown.esc.prevent="escapeClick"
                    >
                        <Toggle v-if="multiple" :modelValue="isItemActive(item)" />
                        <slot name="item" v-bind:item="item"></slot>
                        <span v-if="suffix" class="v-dropdown-menu__item-suffix">{{ suffix }}</span>
                    </li>
                </ul>
            </template>
        </DropdownMenu>
        <div v-if="hint" class="dropdown-menu__hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu';
import Toggle from '@/components/atoms/Toggle.vue';

export default {
    name: 'Dropdown',
    components: { Toggle, DropdownMenu },
    props: {
        identifier: {
            type: String,
        },
        modelValue: {},
        items: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {
            type: String,
        },
        hint: {
            type: String,
        },
        suffix: {
            type: String,
        },
        filterField: {
            type: String,
            required: false,
        },
        filterFunction: {
            type: Function,
            required: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isOpened: false,
            selectedItems: [],
            shouldOpenUpwards: false,
            searchTerm: '',
            focusedIndex: 0,
        };
    },
    created() {
        if (this.modelValue) {
            this.selectedItems =
                this.modelValue instanceof Array ? this.modelValue : [this.modelValue];
        }
    },
    computed: {
        classes() {
            return {
                'v-dropdown-menu--multiselect': this.multiple,
                'v-dropdown-menu--has-item': this.selectedItems.length,
                'v-dropdown-menu--open-to-top': this.shouldOpenUpwards,
                'v-dropdown-menu--not-clearable': !this.clearable,
            };
        },
        selectedItemsSorted() {
            const items = [...this.selectedItems];
            items.sort();

            return items;
        },
        filteredItems() {
            if (this.filterField) {
                return this.items.filter((i) =>
                    i[this.filterField]?.toLowerCase().includes(this.searchTerm.toLowerCase()),
                );
            }
            if (this.filterFunction) {
                return this.items.filter((item) => this.filterFunction(item, this.searchTerm));
            }

            return this.items.filter((item) =>
                item.toString().toLowerCase().includes(this.searchTerm.toLowerCase()),
            );
        },
        selectedFilteredItems() {
            const items = [...this.filteredItems.filter((i) => this.selectedItems.includes(i))];
            items.sort();

            return items;
        },
        filteredItemsWithoutSelected() {
            return this.filteredItems.filter((i) => !this.selectedItems.includes(i));
        },
    },

    methods: {
        onClosed() {
            this.isOpened = false;
            this.searchTerm = '';
            this.focusedIndex = 0;

            window.removeEventListener('click', this.onOutsideClick);
        },
        onOpened() {
            // Determine whether to open upwards or downwards.
            const dropdownRect = this.$el.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const distanceToBottom = viewportHeight - dropdownRect.bottom;

            this.shouldOpenUpwards = distanceToBottom < 300;
            this.isOpened = true;

            this.$nextTick(() => {
                this.$refs.searchInput.focus();
            });

            window.addEventListener('click', this.onOutsideClick);
        },
        escapeClick() {
            this.onClosed();
        },
        onOutsideClick(event) {
            const dropdown = this.$refs.dropdown?.$el;

            if (
                !dropdown ||
                !this.isOpened ||
                (this.multiple && event.target.classList.contains('v-dropdown-menu__item'))
            )
                return;

            if (!(dropdown === event.target || dropdown.contains(event.target))) {
                this.isOpened = false;
            }
        },
        onItemClick(item) {
            this.onInput(item);

            if (!this.multiple) {
                this.isOpened = false;
            }
        },
        onInput(item) {
            const currentItemIndex = this.focusedIndex;

            if (this.multiple) {
                if (!this.selectedItems.includes(item)) {
                    this.selectedItems.push(item);
                } else {
                    this.selectedItems.splice(
                        this.selectedItems.findIndex((i) => i === item),
                        1,
                    );
                }
            } else {
                this.selectedItems = [item];
            }
            this.$emit('update:modelValue', this.selectedItems);
            this.updateFocusedIndex(currentItemIndex);
        },
        onClearClick() {
            this.selectedItems = [];
            this.isOpened = false;
            this.searchTerm = '';

            this.$emit('update:modelValue', []);
        },
        isItemActive(item) {
            return this.selectedItems.includes(item);
        },
        focusNextItem() {
            const options = this.$refs.options;
            if (!options || options.children.length === 0) return;

            if (document.activeElement === this.$refs.searchInput) {
                this.focusedIndex = this.shouldOpenUpwards ? options.children.length - 1 : 0;
            } else {
                this.focusedIndex = (this.focusedIndex + 1) % options.children.length;
            }

            options.children[this.focusedIndex].focus();
        },

        focusPrevItem() {
            const options = this.$refs.options;
            if (!options || options.children.length === 0) return;

            if (document.activeElement === this.$refs.searchInput) {
                this.focusedIndex = this.shouldOpenUpwards ? 0 : options.children.length - 1;
            } else {
                this.focusedIndex =
                    (this.focusedIndex - 1 + options.children.length) % options.children.length;
            }

            options.children[this.focusedIndex].focus();
        },
        confirmSelection() {
            const options = this.$refs.options;
            const combinedFilteredItems = [
                ...this.selectedFilteredItems,
                ...this.filteredItemsWithoutSelected,
            ];

            if (options && options.children.length > 0) {
                const focusedItem = combinedFilteredItems[this.focusedIndex];

                if (focusedItem) {
                    this.onInput(focusedItem);
                    if (!this.multiple) {
                        this.isOpened = false;
                    }
                }
            }
        },
        updateFocusedIndex(currentItemIndex) {
            const combinedFilteredItems = [
                ...this.selectedFilteredItems,
                ...this.filteredItemsWithoutSelected,
            ];
            if (currentItemIndex < combinedFilteredItems.length) {
                this.focusedIndex = currentItemIndex;
            } else {
                this.focusedIndex = 0;
            }
            this.$nextTick(() => {
                const options = this.$refs.options;
                if (options && options.children.length > 0) {
                    options.children[this.focusedIndex].focus();
                }
            });
        },
    },
    watch: {
        modelValue(newValue, oldValue) {
            this.selectedItems = newValue instanceof Array ? newValue : [newValue];
        },
    },
};
</script>
