<template>
    <div class="no-service"></div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app';

export default {
    name: 'Maintenance',
    components: {
        Btn,
    },
    mounted() {
        if (!this.inMaintenanceState) {
            this.$router.replace({ name: 'country-select', query: this.$route.query });
        }
    },
    computed: {
        ...mapState(useAppStore, ['inMaintenanceState']),
    },
};
</script>
