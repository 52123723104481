<template>
    <section class="seeds-form" sticky-container>
        <transition name="loading-overlay">
            <LoadingOverlay v-if="!hasLoadingFinished" />
            <MultiForm v-else ref="form" @onStepChanged="onFormStepChange">
                <div v-show="currentFormStep === formStepKeys.CROP">
                    <ParameterSection
                        :title="$t('form.selectCrop')"
                        class="parameter-section__crops"
                    >
                        <template #content>
                            <OptionSelectList
                                v-if="!$loading.isLoading('crop-protection:load-crops')"
                                name="crops"
                                :options="sortedCrops"
                                grid
                                :gridColumns="3"
                                :modelValue="selectedCrop"
                                @update:modelValue="(crop) => onCropChange(crop)"
                            >
                                <template #content="props">
                                    <SelectButton
                                        :title="
                                            $t(
                                                `crops.${
                                                    props.item[$translationMappingKey('crops')]
                                                }.name`,
                                            )
                                        "
                                        :image="getCropImage(props.item)"
                                        isSVGImage
                                        :active="props.active"
                                    />
                                </template>
                            </OptionSelectList>
                            <Btn
                                v-if="selectedCountryMeta?.showMoreCropsLink"
                                :href="selectedCountryMeta.showMoreCropsLink"
                                target="_blank"
                                type="text"
                                icon="fa-arrow-up-right"
                                iconPosition="start"
                                class="more-crops-link"
                                >{{ $t('header.moreCropsOnOurWebsite') }}
                            </Btn>
                        </template>
                    </ParameterSection>
                </div>
                <div v-if="currentFormStep === formStepKeys.PARAMETERS">
                    <!--Tree-->
                    <template
                        v-if="
                            !$loading.isLoading('crop-protection:load-parameter-configuration') &&
                            parameterConfiguration &&
                            parameterConfiguration.recommendationFlowParameters &&
                            parameterConfiguration.additionalFilterParameters
                        "
                    >
                        <DecisionTree :parameterConfiguration="parameterConfiguration" ref="tree" />
                        <CharacteristicsToggles
                            v-if="parameterConfiguration?.recommendationCharacteristics?.length"
                        />
                    </template>
                    <div v-else>loading parameter tree..</div>
                </div>
                <div v-if="currentFormStep === formStepKeys.SEED_RESULT">
                    <Recommendation :recommendations="seedRecommendations" forSeeds />
                </div>
            </MultiForm>
        </transition>
    </section>
</template>
<script>
import LoadingOverlay from '@/components/atoms/LoadingOverlay.vue';
import OptionSelectList from '@/components/molecules/OptionSelectList.vue';
import SelectButton from '@/components/atoms/SelectButton.vue';
import MultiForm from '@/components/molecules/MultiForm.vue';
import { mapActions, mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import FormStepKeys from '@/utils/constants/form-step-keys';
import ParameterSection from '@/components/molecules/ParameterSection.vue';
import Btn from '@/components/atoms/Btn.vue';
import NotificationBox from '@/components/atoms/NotificationBox.vue';
import DecisionTree from '@/components/molecules/DecisionTree.vue';
import { useDeeplinkStore } from '@/stores/deeplink';
import Recommendation from '@/components/organisms/Recommendation.vue';
import { track } from '@/utils/utag';
import { useSeedStore } from '@/stores/seed';
import CharacteristicsToggles from '@/components/molecules/CharacteristicsToggles.vue';
import TrackingTypes from '@/utils/constants/trackingTypes';
import { useTeaserStore } from '@/stores/teaser';

export default {
    name: 'SeedsForm',
    components: {
        CharacteristicsToggles,
        Recommendation,
        DecisionTree,
        NotificationBox,
        Btn,
        ParameterSection,
        MultiForm,
        SelectButton,
        OptionSelectList,
        LoadingOverlay,
    },
    data() {
        return {
            initialized: false,
        };
    },
    async created() {
        const steps = [
            {
                title: 'bottomBar.seedingRequirements',
                heroTitle: 'header.seedingRequirements',
                identifier: FormStepKeys.PARAMETERS,
            },
            {
                title: 'bottomBar.results',
                heroTitle: 'recommendations.recommendedTankMixtures',
                identifier: FormStepKeys.SEED_RESULT,
            },
        ];

        await this.loadCrops(true);
        let nextStep = FormStepKeys.CROP;

        steps.unshift({
            title: 'bottomBar.crop',
            heroTitle: 'header.selectCrop',
            identifier: FormStepKeys.CROP,
        });

        if (this.crops.length === 1) {
            await this.setSelectedCrop(this.crops[0]);
        }

        this.setFormSteps(steps);
        this.setCurrentStep(nextStep);

        this.initialized = true;

        // Subscribe to all form changes and pass to deeplink store.
        const formStore = useConfigurationFormStore();
        formStore.$subscribe((mutation, state) => {
            this.updateDeeplink(state);
        });

        // Apply deeplink values if present.
        await this.validateAndApplyDeeplink(true);
        this.triggerUpdateDeeplink();
    },
    async mounted() {
        // Load promo teasers.
        await this.loadPromotionTeasers('seed');
    },
    computed: {
        ...mapState(useLocalizationStore, [
            'selectedCountryMeta',
            'selectedLanguage',
            'selectedCountry',
        ]),
        ...mapState(useCropProtectionStore, ['crops', 'sortedCrops', 'parameterConfiguration']),
        ...mapState(useSeedStore, ['seedRecommendations']),
        ...mapState(useConfigurationFormStore, [
            'formSteps',
            'currentFormStep',
            'currentFormStepIndex',
            'canGoBack',
            'canGoForwards',
            'selectedCrop',
        ]),

        formStepKeys() {
            return FormStepKeys;
        },
        hasLoadingFinished() {
            return (
                this.initialized &&
                !this.$loading.isLoading('deeplink:apply-deep-link') &&
                !this.$loading.isLoading('crop-protection:load-crops') &&
                !this.$loading.isLoading('crop-protection:load-parameter-configuration') &&
                !this.$loading.isLoading('crop-protection:load-growth-stages') &&
                !this.$loading.isLoading('crop-protection:load-targets') &&
                !this.$loading.isLoading('seeds:load-recommendations') &&
                !this.$loading.isLoading('crop-protection:load-recommendations')
            );
        },
        shouldDisplayCropSelectionAlert() {
            return this.crops?.length < 2 && this.selectedCrop;
        },
        isParameterConfigExisting() {
            // NULL = there are no parameters but advices
            return (
                this.parameterConfiguration?.recommendationFlowParameters !== null &&
                this.parameterConfiguration?.additionalFilterParameters !== null &&
                typeof this.parameterConfiguration?.recommendationFlowParameters === 'object' &&
                Object.keys(this.parameterConfiguration?.recommendationFlowParameters)?.length > 0
            );
        },
        hasParameterConfigAdvices() {
            return (
                this.parameterConfiguration?.recommendationFlowParameters !== false &&
                this.parameterConfiguration?.additionalFilterParameters !== false
            );
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, [
            'setSelectedCrop',
            'setFormSteps',
            'setCurrentStep',
            'validateParameterConfiguration',
        ]),
        ...mapActions(useCropProtectionStore, ['loadCrops', 'loadParameterConfiguration']),
        ...mapActions(useDeeplinkStore, [
            'updateDeeplink',
            'validateAndApplyDeeplink',
            'triggerUpdateDeeplink',
        ]),
        ...mapActions(useSeedStore, ['loadRecommendations', 'toggleCompareMode']),
        ...mapActions(useTeaserStore, ['loadPromotionTeasers']),

        async onFormStepChange(current) {
            this.setCurrentStep(current.step.identifier);

            this.toggleCompareMode(false);

            if (current.step.identifier === FormStepKeys.PARAMETERS) {
                await this.loadParameterConfiguration(this.selectedCrop.eppoCode, true);

                if (!this.hasParameterConfigAdvices || !this.isParameterConfigExisting) {
                    this.$notify(this.$t('errors.cropProtection.noAdvicesForCrop'), 'warning');
                    this.setCurrentStep(FormStepKeys.CROP);
                    return;
                }

                await this.$nextTick(() => {
                    this.triggerUpdateDeeplink();
                    this.validateParameterConfiguration();
                });
            } else if (current.step.identifier === FormStepKeys.SEED_RESULT) {
                await this.loadRecommendations();
            }
        },
        async onCropChange(crop) {
            await this.setSelectedCrop(crop);

            if (this.$refs.tree) {
                this.$refs.tree.resetTree();
            }
        },
        getCropImage(crop) {
            return new URL(`../../assets/images/icons/crops/${crop.eppoCode}.svg`, import.meta.url)
                .href;
        },
    },
    watch: {
        currentFormStep: {
            immediate: true,
            handler(current) {
                const data = {
                    page_category: TrackingTypes.Category.Seed,
                    page_subcategory: '',
                    page_country: this.selectedCountry,
                    page_language: this.selectedLanguage.language,
                    page_type: TrackingTypes.Type.RecommendationFlow,
                    page_privacy_policy: false,
                };

                if (current === FormStepKeys.CROP) {
                    data.page_name = 'Step 1 - Crop';
                } else if (current === FormStepKeys.PARAMETERS) {
                    data.page_name = 'Step 2 - Parameters';
                } else if (current === FormStepKeys.SEED_RESULT) {
                    data.page_name = 'Recommendation';
                    data.page_type = TrackingTypes.Type.Recommendation;
                }

                track(data);
            },
        },
    },
};
</script>
