import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import captureError from '@/utils/capture-error';
import { aceApiClient } from '@/utils/axios';

export const useAppStore = defineStore('app', {
    state: () => ({
        translationMappings: {},
        isMobileVersion: false,
        inMaintenanceState: false,
    }),
    actions: {
        async getInitialData() {
            const route = apiRoute('init');

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'app:init',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'app:init');
                return;
            }

            if (result) {
                this.translationMappings = result.data.translation_mappings;
            }
        },
        setIsMobileVersion(isMobileVersion) {
            this.isMobileVersion = isMobileVersion;
        },
        toggleMaintenanceState(isEnabled) {
            if (import.meta.env.DEV) {
                this.$notify(`Maintenance state would be ${isEnabled ? 'enabled' : 'disabled'} now. Due to dev mode, the state will be ignored.`);
                return;
            }

            this.inMaintenanceState = isEnabled;

            if (this.inMaintenanceState) {
                this.router.replace({
                    name: 'maintenance',
                    query: this.router.currentRoute?.query,
                });
            } else {
                this.router.replace({
                    name: 'country-select',
                    query: this.router.currentRoute?.query,
                });
            }
        },
    },
    getters: {
        translationMappingKey: (state) => (entity) => state.translationMappings[entity],

        currentService: (state) => {
            return state.router.currentRoute?.name?.split('.')[0] ?? null;
        },
    },
});
