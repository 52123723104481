<template>
    <div v-if="!$loading.isLoading('teaser:load-teasers')" class="service-select">
        <GridList class="service-select__list" :columns="3">
            <ServiceTeaser
                v-for="service in availableServicesOrdered"
                :title="getTeaserTranslation(service.title)"
                :category="getServiceTypeLabel(service)"
                :linkText="getTeaserTranslation(service.button)"
                :link="getServiceLink(service)"
                :isExternal="service.target !== 'crop' && service.target !== 'seed'"
                :text="getTeaserTranslation(service.text)"
                :icon="getTeaserIcon(service)"
            />
        </GridList>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import GridList from '@/components/atoms/GridList.vue';
import ServiceTeaser from '@/components/atoms/ServiceTeaser.vue';
import { useTeaserStore } from '@/stores/teaser';
import { useLocalizationStore } from '@/stores/localization';
import { track } from '@/utils/utag';
import TrackingTypes from '@/utils/constants/trackingTypes';

export default {
    name: 'ServiceSelect',
    components: { ServiceTeaser, GridList },
    async created() {
        if (this.availableServices.length === 0) {
            this.$router.push({
                name: 'country-select',
                query: this.$route.query,
            });
        } else if (this.availableServices.length === 1) {
            this.$router.push({
                ...this.getServiceLink(this.availableServices[0]),
                query: this.$route.query,
            });
        }
    },
    computed: {
        ...mapState(useTeaserStore, [
            'availableServicesOrdered',
            'availableServices',
            'getServiceLink',
            'getServiceTypeLabel',
            'getTeaserTranslation',
            'getTeaserIcon',
        ]),
        ...mapState(useLocalizationStore, ['selectedCountry', 'selectedLanguage']),
    },
    methods: {
        ...mapActions(useTeaserStore, ['loadServiceTeasers']),
    },
    mounted() {
        track({
            page_name: 'Service select',
            page_category: TrackingTypes.Category.General,
            page_subcategory: '',
            page_country: this.selectedCountry,
            page_language: this.selectedLanguage.language,
            page_type: TrackingTypes.Type.UpstreamPage,
            page_privacy_policy: false,
        });
    },
};
</script>
