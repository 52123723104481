<template>
    <div class="recommendation-card-list">
        <GridList :columns="3">
            <li v-for="advice in advices">
                <RecommendationCard
                    :advice="advice"
                    :isSeedCard="showSeedCards"
                    :cropStrategyImage="getCropStrategyImageForAdvice(advice.id)?.content"
                    :key="`advice-${advice.id}`"
                />
            </li>
            <li>
                <UpsellingCard />
            </li>
        </GridList>
    </div>
</template>

<script>
import RecommendationCard from '@/components/molecules/Recommendation/RecommendationCard.vue';
import GridList from '@/components/atoms/GridList.vue';
import { binaryToBase64, groupBy } from '@/utils/helpers';
import { mapActions } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import UpsellingCard from '@/components/molecules/Recommendation/UpsellingCard.vue';

export default {
    name: 'RecommendationCardList',
    components: { UpsellingCard, GridList, RecommendationCard },
    props: {
        advices: {
            type: Array,
            required: true,
        },
        showSeedCards: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            cropStrategyImages: [],
        };
    },
    async mounted() {
        setTimeout(async () => {
            const adviceIds = this.advices.map((a) => a.id);
            const images = await this.loadAdviceImages(adviceIds);

            images?.forEach((img) => {
                img.content = binaryToBase64(img.content);
            });

            this.cropStrategyImages = images;
        }, 0);
    },
    methods: {
        ...mapActions(useCropProtectionStore, ['loadAdviceImages']),

        getCropStrategyImageForAdvice(adviceId) {
            return this.cropStrategyImages?.find((i) => {
                return i.adviceIds?.includes(adviceId);
            });
        },
    },
    computed: {
        advicesGroupedByFunctionalGroup() {
            return groupBy(this.advices, ({ functionalGroupCode }) => functionalGroupCode);
        },
    },
};
</script>
