import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import { useLocalizationStore } from '@/stores/localization';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import captureError from '@/utils/capture-error';
import { aceApiClient } from '@/utils/axios';
import { addTranslations } from '@/utils/build-translations';
import ParameterInputTypes from '@/utils/constants/parameter-input-types';
import _, { orderBy, uniqBy } from 'lodash';
import { findObjectsInNestedStructure } from '@/utils/nested-helpers';
import { useDeeplinkStore } from '@/stores/deeplink';
import { useAppStore } from '@/stores/app';
import { useSeedStore } from '@/stores/seed';
import { efficacyStringToNumber } from '@/utils/helpers';

// Load all GS-images directly, so they don't get lost when building.
const allGrowthStageImages = import.meta.glob(
    '/src/assets/images/growth-stage-illustrations/*.svg',
    { as: 'raw' },
);

export const useCropProtectionStore = defineStore('crop-protection', {
    state: () => ({
        crops: [],
        growthStagesCodeIdMapping: {},
        growthStages: [],
        targets: [],

        parameterConfiguration: null,
        cropRecommendations: {},
        growthStageImages: null,
        growthStageIllustrationMapping: null,

        canLoadEntity: {
            // Keep track which entities should be loaded/reloaded.
            parameterConfiguration: true,
            growthStages: true,
            targets: true,
            recommendations: true,
        },

        configurationFormStore: useConfigurationFormStore(),
    }),
    actions: {
        async loadCrops(forSeeds = false) {
            const countryStore = useLocalizationStore();
            const countryCode = countryStore.selectedCountry;
            const options = {
                countryCode,
                hasAdvices: true,
            };

            if (forSeeds) {
                options['division'] = 'seed';
            }

            const route = apiRoute('crops', null, options);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'crop-protection:load-crops',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'crop-protection:load-crops', true, 'cropProtection.loadCrops');
                return;
            }

            if (result) {
                this.crops = result.data;

                this.setShouldLoadStatusForEntity('parameterConfiguration', true);
            }
        },

        async loadParameterConfiguration(cropEppoCode, forSeeds = false) {
            if (
                !this.canLoadEntityData('parameterConfiguration') &&
                this.parameterConfiguration !== null
            ) {
                return null;
            }

            const countryStore = useLocalizationStore();
            const options = {
                hasAdviceCountryCode: countryStore.selectedCountry,
                cropEppoCode: cropEppoCode,
            };

            if (forSeeds) {
                options['division'] = 'seed';
            }

            const route = apiRoute('parameterConfiguration', null, options);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'crop-protection:load-parameter-configuration',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(
                    err,
                    'crop-protection:load-parameter-configuration',
                    true,
                    'cropProtection.loadParameterConfiguration',
                );
                return null;
            }

            if (result && result.data) {
                // Clear region default value
                findObjectsInNestedStructure(
                    result.data.recommendationFlowParameters,
                    'apiExternalIdentifier',
                    'hasAdviceRegion',
                ).forEach((regionParam) => (regionParam.defaultValues = []));

                this.parameterConfiguration = result.data;
                this.setShouldLoadStatusForEntity('parameterConfiguration', false);

                // Clear additional filters.
                this.configurationFormStore.clearAdditionalFilterInputs();

                if (forSeeds) {
                    // Set characteristic defaults.
                    this.configurationFormStore.setCharacteristicsFromList(
                        this.parameterConfiguration.recommendationCharacteristics,
                    );
                } else {
                    this.configurationFormStore.clearCharacteristics();
                }
            }

            return this.parameterConfiguration;
        },

        async loadGrowthStages() {
            if (!this.canLoadEntityData('growthStages') && this.growthStages.length > 0) {
                return false;
            }

            if (!this.configurationFormStore.selectedCrop) return false;

            // Reset svg image.
            this.growthStageImages = null;
            this.growthStageIllustrationMapping = null;

            const cropEppoCode = this.configurationFormStore.selectedCrop.eppoCode;
            const baseParams = this.configurationFormStore.baseRequestParameters();
            const localizationStore = useLocalizationStore();

            const params = {
                ...baseParams,
                hasAdvices: true,
                languages: localizationStore.availableLanguages.map((l) => l.language),
                cropEppoCode,
            };

            const route = apiRoute('growthStages', null, params);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'crop-protection:load-growth-stages',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(
                    err,
                    'crop-protection:load-growth-stages',
                    true,
                    'cropProtection.loadGrowthStages',
                );
                return false;
            }

            this.configurationFormStore.cleanUpSelectedTargets();

            if (result && result.data) {
                this.growthStages = result.data.some((gs) => gs.isPrioritized)
                    ? result.data.filter((gs) => gs.isPrioritized)
                    : result.data;

                this.growthStagesCodeIdMapping = result.data.reduce((acc, gs) => {
                    acc[gs.code] = gs.id;
                    return acc;
                }, {});

                // Auto-select first growth-stage if none is selected or selected stage is unavailable.
                const foundGrowthStage = this.growthStages.find(
                    (gs) => gs.code === this.configurationFormStore.selectedGrowthStage?.code,
                );

                if (!this.configurationFormStore.selectedGrowthStage || !foundGrowthStage) {
                    await this.configurationFormStore.setSelectedGrowthStage(
                        foundGrowthStage ?? this.growthStages[0],
                    );
                }

                this.setShouldLoadStatusForEntity('growthStages', false);
                this.setShouldLoadStatusForEntity('targets', true);

                const localizationStore = useLocalizationStore();

                addTranslations(
                    this.$i18n,
                    'growthStages',
                    result.data,
                    'id',
                    localizationStore.availableLanguages.map((l) => l.locale),
                );

                // Load image & mapping.
                const svgWrapper = this.configurationFormStore.selectedCrop?.svg;

                if (svgWrapper && Object.keys(svgWrapper).length) {
                    const growthStageFileName = svgWrapper?.filename;
                    this.growthStageIllustrationMapping = svgWrapper?.illustrationMappings?.find(
                        (m) => m.growthScaleCode === 'BBCH',
                    )?.growthStageGraphicMapping;

                    this.growthStageImages = await this.loadGrowthStageImage(growthStageFileName);
                }

                return true;
            }
        },
        async loadGrowthStageImage(identifier) {
            const path = `/src/assets/images/growth-stage-illustrations/${identifier}`;

            if (allGrowthStageImages[path]) {
                return await allGrowthStageImages[path]();
            }
        },

        async loadRecommendations() {
            if (
                !this.canLoadEntityData('recommendations') &&
                this.cropRecommendations.advices?.length > 0
            ) {
                return;
            }

            if (
                !this.configurationFormStore.selectedCrop ||
                !this.configurationFormStore.selectedGrowthStage
            )
                return;

            this.cropRecommendations = {};

            const baseParams = this.configurationFormStore.baseRequestParameters(true);
            const localizationStore = useLocalizationStore();

            const body = {
                ...baseParams,
                ...this.additionalFilterParameters,
                cropEppoCode: this.configurationFormStore.selectedCrop.eppoCode,
                growthStage: this.configurationFormStore.selectedGrowthStage.code,
                growthStageScale:
                    this.configurationFormStore.selectedGrowthStage.growthStageScaleName,
                targetEppoCodes: this.configurationFormStore.activeTargets.map((p) => p.eppoCode),
                languages: localizationStore.availableLanguages.map((l) => l.language).join(','),
            };

            if (
                this.configurationFormStore.enteredTreeValues[
                    'hasAdviceFunctionalGroupCodes'
                ]?.value?.includes('PGR')
            ) {
                body['isGrowthRegulatorIncluded'] = true;
            }

            const route = apiRoute('recommendations');

            this.$loading.startLoading('crop-protection:load-recommendations');

            const [err, result] = await to(aceApiClient.post(route, body));

            if (err) {
                captureError(
                    err,
                    'crop-protection:load-recommendations',
                    true,
                    'cropProtection.loadRecommendations',
                );
                this.$loading.endLoading('crop-protection:load-recommendations');

                return;
            }

            if (result) {
                this.cropRecommendations = result.data;

                this.setShouldLoadStatusForEntity('recommendations', false);
            }

            this.$loading.endLoading('crop-protection:load-recommendations');
        },
        async sendRecommendationsMail(options) {
            const route = apiRoute('recommendationsMail');
            const deeplinkStore = useDeeplinkStore();
            const appStore = useAppStore();
            const countryStore = useLocalizationStore();

            options['locale'] = countryStore.selectedLanguage?.locale;
            options['deeplink'] = deeplinkStore.fullUrl;
            options['cropId'] = this.configurationFormStore.selectedCrop.id;
            options['growthStageId'] = this.configurationFormStore.selectedGrowthStage.id;
            options['division'] = appStore.currentService === 'seeds' ? 'seed' : 'crop-protection';

            options['parameters'] = this.generateFormParams(
                this.configurationFormStore.enteredTreeValues,
                false,
                'id',
            );

            this.$loading.startLoading('crop-protection:send-recommendations-mail');

            const [err, result] = await to(aceApiClient.post(route, options));

            if (err) {
                captureError(
                    err,
                    'crop-protection:send-recommendations-mail',
                    true,
                    'crop-protection:sendRecommendationsMail',
                );
                this.$loading.endLoading('crop-protection:send-recommendations-mail');
            }

            if (result && result.status === 200) {
                return true;
            }

            this.$loading.endLoading('crop-protection:send-recommendations-mail');

            return false;
        },

        async loadTargets() {
            if (!this.canLoadEntityData('targets') && this.targets.length > 0) {
                return;
            }

            const baseParams = this.configurationFormStore.baseRequestParameters();
            const params = {
                ...baseParams,
                hasAdviceGrowthStageScale:
                    this.configurationFormStore.selectedGrowthStage?.growthStageScaleName,
                hasAdviceGrowthStage: this.configurationFormStore.selectedGrowthStage?.code,
                hasAdviceCropEppoCode: this.configurationFormStore.selectedCrop?.eppoCode,
            };

            const route = apiRoute('targets', null, params);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'crop-protection:load-targets',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(
                    err,
                    'crop-protection:load-targets',
                    true,
                    'cropProtection.loadTargets',
                );
                return;
            }

            if (result) {
                this.targets = result.data;

                this.setShouldLoadStatusForEntity('targets', false);

                // Clear disabled/unavailable targets.
                this.configurationFormStore.setUserDisabledTargets([]);
                this.configurationFormStore.cleanUpSelectedTargets();
            }
        },
        setShouldLoadStatusForEntity(entity, shouldLoad) {
            // When disabled, the entity will not load new data when it's function is called.
            this.canLoadEntity[entity] = shouldLoad;
        },

        async loadAdviceImages(adviceIds) {
            const params = { adviceIds };
            const route = apiRoute('adviceImages', null, params);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'crop-protection:load-advice-images',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(
                    err,
                    'crop-protection:load-advice-images',
                    true,
                    'cropProtection.loadAdviceImages',
                );
                return null;
            }

            if (result) {
                return result.data.map((i) => ({
                    adviceIds: i.adviceIds,
                    content: i.content,
                }));
            }
        },
    },
    getters: {
        // Get object with countryCode & entered parameter values for requests.
        additionalFilterParameters: (state) => {
            const enteredValues = state.configurationFormStore.additionalFilterInputs;
            const formParams = state.configurationFormStore.generateFormParams(enteredValues, true);

            return {
                ...formParams,
            };
        },
        generateFormParams:
            (state) =>
            (
                enteredValues,
                usedInRecommendationRequest = false,
                customParameterIdentifier = null,
            ) => {
                let keyForRequest = usedInRecommendationRequest
                    ? 'externalIdentifier'
                    : 'apiExternalIdentifier';

                if (customParameterIdentifier !== null) {
                    keyForRequest = customParameterIdentifier;
                }

                return Object.keys(enteredValues).reduce((acc, key) => {
                    const param = enteredValues[key];
                    const identifier = param?.parameter[keyForRequest];
                    const isMultiSelect =
                        param?.parameter?.type === ParameterInputTypes.MULTI_SELECT;

                    const val = isMultiSelect ? param?.value : param?.value[0];

                    // Make sure we don't send empty values.
                    if (
                        val !== null &&
                        val !== undefined &&
                        (val.length || typeof val === 'boolean' || typeof val === 'number')
                    ) {
                        acc[identifier] = val;
                    }

                    return acc;
                }, {});
            },

        getGrowthStageByCode: (state) => (code) =>
            state.growthStages.find((gs) => gs.code === code),
        getTargetByEppoCode: (state) => (eppoCode) =>
            state.targets.find((t) => t.eppoCode === eppoCode),
        getCropByEppoCode: (state) => (eppoCode) =>
            state.crops.find((c) => c.eppoCode === eppoCode),

        getProductById: (state) => (id) => {
            const seedStore = useSeedStore();
            const allProducts = [
                ...(state.cropRecommendations?.products ?? []),
                ...(seedStore.seedRecommendations?.products ?? []),
            ];

            return allProducts?.find((p) => p.id === id);
        },

        getPackById: (state) => (id) => state.cropRecommendations?.packs?.find((p) => p.id === id),
        canLoadEntityData: (state) => (entity) => !!state.canLoadEntity[entity],
        getAdviceTitle:
            (state) =>
            (advice, separator = ', ', shouldWrapItems = false) => {
                const adviceProducts = [...advice.adviceProducts];
                const adviceWithPackId = adviceProducts.find((p) => p.packId);

                adviceProducts.sort((a, b) => a.order - b.order);

                if (adviceWithPackId) {
                    return state.$i18n.t(`packs.${adviceWithPackId.packId}.name`);
                } else {
                    return adviceProducts
                        .map((p) => {
                            const productName = state.$i18n.t(`products.${p.productId}.name`);
                            return shouldWrapItems ? `<span>${productName}</span>` : productName;
                        })
                        .join(separator);
                }
            },
        getAdviceOverallEffect: (state) => (advice) => {
            // Take and flatten all efficacies from all products.
            const adviceProductEfficacies = advice.adviceProducts
                .map((p) => p.efficacies)
                .reduce((acc, val) => acc.concat(...val), [])
                .map((e) => e.targetEfficacies)
                .reduce((acc, val) => acc.concat(...val), []);

            const foundTargets = adviceProductEfficacies.map((t) => t.targetEppoCode);
            const missingTargets = state.configurationFormStore.activeTargets.filter(
                (t) => !foundTargets.includes(t.eppoCode),
            );

            // Sum all efficacy amounts and calculate avg.
            let allTargetsToCalculate = uniqBy(
                orderBy(adviceProductEfficacies, 'amount', 'desc'),
                'targetEppoCode',
            );
            allTargetsToCalculate.push(
                ...missingTargets.map((t) => ({
                    targetEppoCode: t.eppoCode,
                    amount: '0',
                })),
            );

            allTargetsToCalculate = allTargetsToCalculate.filter((e) => {
                const foundTarget = state.targets.find((t) => t.eppoCode === e.targetEppoCode);
                return foundTarget?.functionalGroupCode === advice.functionalGroupCode;
            });

            const sum = allTargetsToCalculate.reduce(
                (acc, val) => acc + efficacyStringToNumber(val.amount),
                0,
            );

            return sum / allTargetsToCalculate.length;
        },
        getProductDisplayAmount: (state) => (product) => {
            if (
                !product.applicationRateMin &&
                !product.applicationRateMax &&
                !product.applicationRate
            ) {
                return '';
            }

            const shouldDisplayRange = product.applicationRateMin && product.applicationRateMax;
            const range = shouldDisplayRange
                ? `${product.applicationRateMin} - ${product.applicationRateMax}`
                : '';
            const rangeWithParentheses = shouldDisplayRange ? `(${range}) ` : ' ';
            const unit = state.$i18n.te(`units.${product.applicationRateUnitCode}.custom_short`)
                ? state.$i18n.t(`units.${product.applicationRateUnitCode}.custom_short`)
                : state.$i18n.t(`units.${product.applicationRateUnitCode}.short`);

            return `${product.applicationRate ?? ''} ${
                product.applicationRate ? rangeWithParentheses : range + ' '
            }${unit}`;
        },
        getProductDisplayAmountFreeText: (state) => (freeText, unitCode) => {
            if (!freeText && !unitCode) {
                return '';
            }

            const unit = state.$i18n.t(`units.${unitCode}.short`);

            return `${freeText} ${unit}`;
        },
        sortedCrops: (state) => {
            return _.sortBy(state.crops, 'order');
        },
    },
});
