<template>
    <section class="hero-area" :class="classes" :style="style">
        <Navigation
            :showMetaNavigation="!isStaticView"
            :showServiceNavigation="!!selectedCountry && $route.name !== 'maintenance'"
        />
        <div class="hero-area__content container container--narrow">
            <div
                v-if="
                    isRecommendationStep &&
                    $route.name !== 'no-service' &&
                    $route.name !== 'maintenance'
                "
                class="hero-area__content-buttons"
            >
                <Btn type="text" icon="fa-arrow-left" iconPosition="start" @click="onBackwardsClick"
                    >{{ $t('bottomBar.back') }}
                </Btn>
                <Btn
                    type="default"
                    icon="fa-arrow-rotate-right"
                    iconPosition="end"
                    @click="onRestartClick"
                    >{{ $t('bottomBar.restart') }}
                </Btn>
            </div>
            <div class="hero-area__content-texts">
                <div>
                    <span v-if="!isStaticView" class="hero-area__content-hint">
                        {{ counterText }}</span
                    >
                    <h1 class="hero-area__content-title">{{ title }}</h1>
                    <span v-if="subtitle" class="hero-area__content-subtitle">
                        {{ subtitle }}
                    </span>
                </div>
                <Btn
                    v-if="
                        isRecommendationStep &&
                        $route.name !== 'no-service' &&
                        $route.name !== 'maintenance'
                    "
                    type="default"
                    icon="fa-arrow-rotate-right"
                    iconPosition="end"
                    @click="onRestartClick"
                    >{{ $t('bottomBar.restart') }}
                </Btn>
            </div>
            <HeroAreaParametersSlider
                v-if="
                    isRecommendationStep &&
                    $route.name !== 'no-service' &&
                    $route.name !== 'maintenance'
                "
            />
        </div>
    </section>
</template>

<script>
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { mapState, mapActions } from 'pinia';
import Btn from '@/components/atoms/Btn.vue';
import FormStepKeys from '@/utils/constants/form-step-keys';
import HeroAreaParametersSlider from '@/components/atoms/HeroAreaParametersSlider.vue';
import Navigation from '@/components/molecules/Navigation.vue';
import { useLocalizationStore } from '@/stores/localization';
import { useAppStore } from '@/stores/app';
import { useSeedStore } from '@/stores/seed';

export default {
    name: 'HeroArea',
    components: { Navigation, HeroAreaParametersSlider, Btn },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'formSteps',
            'currentFormStep',
            'currentFormStepIndex',
            'currentFormStepData',
        ]),
        ...mapState(useLocalizationStore, ['selectedCountry']),
        ...mapState(useAppStore, ['currentService']),

        style() {
            const currentService = this.$route?.name?.split('.')[0];
            let bg = 'hero-crops.webp';

            switch (currentService) {
                case 'crop-protection':
                    bg = 'hero-crops.webp';
                    break;
                case 'seeds':
                    bg = 'hero-seeds.webp';
                    break;
            }

            const url = new URL(`../../assets/images/backgrounds/${bg}`, import.meta.url).href;

            return {
                backgroundImage: `url(${url})`,
            };
        },
        classes() {
            return {
                'hero-area--minimal': this.isStaticView,
                'hero-area--recommendation-page': this.isRecommendationStep,
            };
        },
        title() {
            if (this.$route.name === 'country-select') {
                return this.$t('start.selectCountry');
            } else if (this.$route.name === 'service-select') {
                return this.$t('header.headline');
            } else if (this.$route.name === 'no-service') {
                return '';
            } else if (this.$route.name === 'maintenance') {
                return 'Service unavailable';
            }
            return this.$t(this.currentFormStepData?.heroTitle ?? '');
        },
        subtitle() {
            if (this.$route.name === 'country-select') {
                return this.$t('start.moreInformation');
            } else if (this.$route.name === 'service-select') {
                return this.$t('start.solutionSelect');
            } else if (this.$route.name === 'no-service') {
                return '';
            } else if (this.$route.name === 'maintenance') {
                return 'Sorry, our product finder is currently undergoing maintenance. Please try again later.';
            }
            return null;
        },
        counterText() {
            if (!this.isRecommendationStep) {
                const formStep = this.currentFormStepIndex + 1;
                if (formStep > 0) {
                    return this.$t('header.step1').replace('1', formStep);
                }
            }

            return null;
        },
        isRecommendationStep() {
            return this.currentService === 'seeds'
                ? this.currentFormStepData?.identifier === FormStepKeys.SEED_RESULT
                : this.currentFormStepData?.identifier === FormStepKeys.RESULT;
        },
        isStaticView() {
            return (
                this.$route.name === 'country-select' ||
                this.$route.name === 'service-select' ||
                this.$route.name === 'no-service' ||
                this.$route.name === 'maintenance'
            );
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, [
            'setCurrentStep',
            'setSelectedTargets',
            'setSelectedGrowthStage',
            'clearTreeValues',
            'onBackwardsClick',
            'resetAllUserInputs',
        ]),
        ...mapActions(useSeedStore, ['toggleCompareMode']),

        onRestartClick() {
            this.resetAllUserInputs();
            this.toggleCompareMode(false);
            this.setCurrentStep(FormStepKeys.CROP);
        },
    },
};
</script>
