<template>
    <div class="sidebar-characteristic">
        <Accordion v-for="(group, i) in dataGroups" :initiallyOpen="i === 0">
            <template #head>
                <span>{{ group.title }}</span>
            </template>
            <template #content>
                <template v-for="item in group.children">
                    <template v-if="item?.value">
                        <NumberDisplay
                            v-if="item.displayType === 'number'"
                            :value="item.value"
                            :label="getCharacteristicsItemLabel(item)"
                            :max="numberDisplayCount"
                        />
                        <TextDisplay
                            v-else
                            :value="$t(getCardParameterValueTranslationKey(item.value))"
                            :label="getCharacteristicsItemLabel(item)"
                        />
                    </template>
                </template>
            </template>
        </Accordion>
        <div class="sidebar-characteristic__info">
            <span v-if="sourceInformation"
                >{{ $t('recommendations.characteristicCompareSource') }}:
                {{ sourceInformation }}</span
            >
            <template v-if="Object.keys(characteristicSpecificSourceInformation).length">
                <div class="sidebar-characteristic__info-legend-list">
                    <span
                        v-for="(label, i) in Object.keys(characteristicSpecificSourceInformation)"
                    >
                        <span v-for="_ in i + 1">*</span> = {{ label }}
                    </span>
                </div>
            </template>
            <NumberDisplay
                :prefix="seedAdviceSettings?.minCharacteristicRange ?? 1"
                :max="numberDisplayCount"
                :value="seedAdviceSettings?.maxCharacteristicRange ?? 9"
                hideUnderline
                centered
            />
            <div class="sidebar-characteristic__info-legend">
                <span>{{ $t('recommendations.characteristicLegendLowValue') }}</span>
                <span>{{ $t('recommendations.characteristicLegendHighValue') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from '@/components/atoms/Accordion.vue';
import Btn from '@/components/atoms/Btn.vue';
import TextDisplay from '@/components/atoms/TextDisplay.vue';
import NumberDisplay from '@/components/atoms/NumberDisplay.vue';
import { mapState } from 'pinia';
import { useSeedStore } from '@/stores/seed';
import translationArrayHelper from '@/utils/translation-array-helper';

export default {
    name: 'SidebarCharacteristic',
    components: { NumberDisplay, TextDisplay, Btn, Accordion },
    props: {
        content: {},
    },
    computed: {
        ...mapState(useSeedStore, [
            'seedRecommendations',
            'getDataValueFromDataListItem',
            'getCardParameterValueTranslationKey',
            'seedAdviceSettings',
        ]),

        dataGroups() {
            const dataGroups = this.seedRecommendations.secondaryDataList;

            dataGroups.sort((a, b) => a.order - b.order);

            // Prepare all the data for each group.
            let transformedData = dataGroups.map((g) => ({
                title: this.$t(`seedAdviceSettingsGroup.${g.externalIdentifier}`),
                children: g.children.map((data) => {
                    let valueWrapper = this.getDataValueFromDataListItem(
                        data,
                        this.content.product,
                    );
                    let value = valueWrapper?.value;
                    let displayType = valueWrapper?.type;

                    if (typeof value === 'string') {
                        value = this.$t(value);
                    }

                    let label =
                        data.translatedNames && data.translatedNames.length
                            ? translationArrayHelper(
                                  data.translatedNames,
                                  this.$i18n.locale,
                                  'locale',
                              )
                            : data.externalIdentifier;

                    if (data.type === 'custom' && data.externalIdentifier === 'seedRate') {
                        const varietyType = this.content.product.varietySeedType;
                        label = `recommendations.seedRate${
                            varietyType.charAt(0).toUpperCase() + varietyType.slice(1)
                        }`;
                    }

                    if (label) {
                        label = this.$t(label);
                    }

                    const characteristicIdentifier = data.externalIdentifier;

                    return {
                        characteristicIdentifier,
                        label,
                        value,
                        displayType,
                    };
                }),
            }));

            // Filter out groups when none of the 'parameters' has values.
            transformedData = transformedData.filter((group) =>
                group.children.some((child) => child.value),
            );

            return transformedData;
        },
        numberDisplayCount() {
            return (
                (this.seedAdviceSettings?.maxCharacteristicRange ?? 9) -
                (this.seedAdviceSettings?.minCharacteristicRange ?? 1) +
                1
            );
        },
        sourceInformation() {
            const productSources = this.content.product.translatedSources;

            if (!productSources?.length) {
                return null;
            }

            return translationArrayHelper(productSources, this.$i18n.locale, 'locale');
        },
        characteristicSpecificSourceInformation() {
            const characteristics = this.content.product?.characteristics ?? [];
            const meta = this.content.product?.productMetaValues ?? [];

            return {
                ...this.getSourcesFromArray(characteristics),
                ...this.getSourcesFromArray(meta),
            };
        },
    },
    methods: {
        getCharacteristicsItemLabel(item) {
            const sources = this.characteristicSpecificSourceInformation;
            const sourceIndex = Object.keys(sources).findIndex((s) =>
                sources[s].includes(item.characteristicIdentifier),
            );

            return `${item.label}${sourceIndex !== -1 ? '*'.repeat(sourceIndex + 1) : ''}`;
        },
        getSourcesFromArray(list) {
            // Create mapping object ({META_ID:META_TRANSLATED_SOURCE}).
            const sourceMapping = list.reduce((acc, val) => {
                if (val.translatedSources?.length) {
                    acc[val.externalIdentifier] = translationArrayHelper(
                        val.translatedSources,
                        this.$i18n.locale,
                        'locale',
                    );
                }
                return acc;
            }, {});

            // Transform to: {META_TRANSLATED_SOURCE: [META_IDs]}
            const transformedMapping = {};
            Object.keys(sourceMapping).forEach((cId) => {
                if (!transformedMapping.hasOwnProperty(sourceMapping[cId])) {
                    transformedMapping[sourceMapping[cId]] = [];
                }
                transformedMapping[sourceMapping[cId]].push(cId);
            });

            return transformedMapping;
        },
    },
};
</script>
