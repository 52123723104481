import app from '@/main';
import i18n from '@/i18n';
import * as Sentry from '@sentry/browser';
import { useAppStore } from '@/stores/app';

// Define which failed requests should NOT trigger the maintenance mode.
const REQUESTS_ALLOWED_TO_FAIL_WITHOUT_MAINTENANCE = [];

const captureError = (error, prefix, sendToSentry = true, userAlertTranslationKey = null) => {
    // Only log console errors in DEV mode.
    if (import.meta.env.DEV) {
        console.error(`[${prefix}] ${error.message}`);
    }

    // Set maintenance mode if fired request is not in REQUESTS_ALLOWED_TO_FAIL_WITHOUT_MAINTENANCE.
    if (!REQUESTS_ALLOWED_TO_FAIL_WITHOUT_MAINTENANCE.includes(userAlertTranslationKey)) {
        const appStore = useAppStore();
        appStore.toggleMaintenanceState(true);
    }

    if (sendToSentry) {
        // Create Error object if given error is a string.
        if (typeof error === 'string') {
            error = new Error(error);
        }

        // Will not send errors to Sentry in DEV mode.
        Sentry.captureException(error);
    }

    if (userAlertTranslationKey) {
        // Show alert to the user.
        const text = i18n.global.t(`errors.${userAlertTranslationKey}`);
        app.config.globalProperties.$notify(text, 'error');
    }
};

export default captureError;
