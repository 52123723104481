import { createRouter, createWebHistory } from 'vue-router';

import RouterViewWrapper from '@/components/RouterViewWrapper.vue';

// Static views
import CountrySelect from '@/views/static/CountrySelect.vue';
import ServiceSelect from '@/views/static/ServiceSelect.vue';
import NoService from '@/views/static/NoService.vue';
import Maintenance from '@/views/static/Maintenance.vue';
import IFrameTest from '@/views/static/IFrameTest.vue';

// Crop-protection views
import CropProtection from '@/views/crop-protection/CropProtection.vue';
import CropProtectionForm from '@/views/crop-protection/CropProtectionForm.vue';

// Seed views
import Seeds from '@/views/seeds/Seeds.vue';
import SeedsForm from '@/views/seeds/SeedsForm.vue';
import FormStepKeys from '@/utils/constants/form-step-keys';

// Stores
import { useConfigurationFormStore } from '@/stores/configuration-form';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        { path: '/:pathMatch(.*)*', redirect: 'country-select' },
        {
            // Special short-link route.
            path: '/s/:hash?',
            component: RouterViewWrapper,
        },
        {
            path: '/country-select',
            name: 'country-select',
            component: CountrySelect,
        },
        {
            path: '/country/:country?',
            component: RouterViewWrapper,
            children: [
                {
                    path: '',
                    redirect: (to) => {
                        // If no country present, go to country-select.
                        if (!to.params.country) {
                            return { name: 'country-select' };
                        }

                        // Otherwise go to service selection
                        return {
                            name: 'service-select',
                            params: { country: to.params.country },
                        };
                    },
                },
                {
                    path: 'service-select',
                    name: 'service-select',
                    component: ServiceSelect,
                },
                {
                    path: 'no-service',
                    name: 'no-service',
                    component: NoService,
                },
                {
                    path: 'crop-protection',
                    name: 'crop-protection',
                    component: CropProtection,
                    redirect: { name: 'crop-protection.form' },
                    children: [
                        {
                            path: 'form',
                            name: 'crop-protection.form',
                            component: CropProtectionForm,
                        },
                    ],
                    beforeEnter: (to, from, next) => {
                        const store = useConfigurationFormStore();
                        store.resetAllUserInputs(false);
                        store.setCurrentStep(FormStepKeys.CROP);

                        next();
                    },
                },
                {
                    path: 'seeds',
                    name: 'seeds',
                    component: Seeds,
                    redirect: { name: 'seeds.form' },
                    children: [
                        {
                            path: 'form',
                            name: 'seeds.form',
                            component: SeedsForm,
                        },
                    ],
                    beforeEnter: (to, from, next) => {
                        const store = useConfigurationFormStore();
                        store.resetAllUserInputs(false);
                        store.setCurrentStep(FormStepKeys.CROP);

                        next();
                    },
                },
            ],
        },
        {
            path: '/iframe-test',
            name: 'iframe-test',
            component: IFrameTest,
        },
        {
            path: '/maintenance',
            name: 'maintenance',
            component: Maintenance,
        },
    ],
});

export default router;
